import React, { createContext, useState } from 'react';

interface EditProfileContextState {
  isEditProfile: boolean;
  setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ImagePathContextState {
  imagePath: string;
  setImagePath: React.Dispatch<React.SetStateAction<string>>;
}

// Create Context objects
export const EditProfileContext = createContext<EditProfileContextState | undefined>(undefined);
export const ImagePathContext = createContext<ImagePathContextState | undefined>(undefined);

// Create a Provider component for each context
export const EditProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isEditProfile, setIsEditProfile] = useState<boolean>(false);

  return (
    <EditProfileContext.Provider value={{ isEditProfile, setIsEditProfile }}>
      {children}
    </EditProfileContext.Provider>
  );
};

export const ImagePathProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [imagePath, setImagePath] = useState<string>('');

  return (
    <ImagePathContext.Provider value={{ imagePath, setImagePath }}>
      {children}
    </ImagePathContext.Provider>
  );
};
